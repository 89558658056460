<template>
  <v-dialog
    v-model="isShowModal"
    width="90%">
    <v-card>
      <material-card
        :title="$t('thirdParty.updateInfoReceiveVouchers')"
        tile
        color="#01baef"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                :label="$t('user.fullname')"
                v-model="fullname"
              />
            </v-flex>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                :label="$t('user.phone')"
                v-model="phone"
              />
            </v-flex>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                :label="$t('user.email')"
                v-model="email"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="#ff6d6d"
          style="color: #fff;"
          @click="onConfirm()"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import StoreChannelType from 'enum/storeChannelType'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  data: () => ({
    phone: null,
    email: null,
    fullname: null,
    isShowModal: false,
    userId: null,
    valid: false
  }),
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    validateData: function () {
      let validateResult = 'OK'
      if (functionUtils.isEmptyString(this.fullname)) {
        return 'user.fullname'
      }
      if (functionUtils.isEmptyString(this.email)) {
        return 'user.email'
      }
      if (!functionUtils.validateEmail(this.email)) {
        return 'validation.invalidEmail'
      }
      return validateResult
    },
    /**
     * Confirm create delivery info
     */
    onConfirm: function () {
      let result = this.validateData()
      if (result === 'OK') {
        let filter = {
          userId: this.userId,
          phone: this.phone,
          email: this.email,
          fullname: this.fullname,
          storeChannelId: this.getStoreChannelId()
        }
        this.CREATE_DELIVERY_INFO(filter).then(
          function () {
            this.isShowModal = false
            this.$emit('createDeliveryInfoSuccess')
          }.bind(this)
        )
      } else {
        this.ON_SHOW_TOAST({
          message: this.$t(result),
          styleType: ToastType.ERROR
        })
      }
    },
    onShowModal: function () {
      this.isShowModal = true
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      this.GET_DELIVERY_INFO_DETAIL(filter).then(
        function (res) {
          let data = res.data
          this.phone = data.phone
          this.email = data.email
          this.fullname = data.fullname
        }.bind(this)
      )
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_DELIVERY_INFO_DETAIL',
      'CREATE_DELIVERY_INFO' // checkUpdate
    ])
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
  /deep/.required label::after {
    content: " *";
    color: red;
  }
</style>
