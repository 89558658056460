<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-layout
      layout
      wrap>
      <v-flex
        xs12
        md12>
        <div>
          <v-icon
            class="mr-2"
            color="#01baef">mdi-account</v-icon>
          <span>
            {{ fullname }}
          </span>
          <span class="mx-3">|</span>
          <span>{{ phone }}</span>
        </div>
        <div class="d-flex justify-content-around align-center">
          <div>
            <span><v-icon
              class="mr-2"
              color="#01baef">mdi-email</v-icon>{{ email }}</span>
          </div>
          <div
            class="text-right"
            @click="onShowModalUpdateDeliveryInfo">
            <span
              style="color: #ff6d6d"
              class="font-weight-bold"
              @click="onShowModalUpdateDeliveryInfo">{{ $t('common.edit') }}</span>
          </div>
        </div>
      </v-flex>
      <v-flex
        xs12
        md12>
        <v-btn
          color="#ff6d6d"
          style="color: #fff; width: 100%; margin: 0;"
          class="mt-4"
          @click="onConfirm()">
          {{ $t('common.confirm') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <modalUpdateDeliveryInfo
      ref="modalUpdateDeliveryInfo"
      @createDeliveryInfoSuccess="onCreateDeliveryInfoSuccess()" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalUpdateDeliveryInfo from './Modal/DeliveryInfo.vue'
import StoreChannelType from 'enum/storeChannelType'
import functionUtils from 'utils/functionUtils'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  components: {
    ModalUpdateDeliveryInfo
  },
  data: () => ({
    userId: null,
    fullname: null,
    email: null,
    phone: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  created () {
    this.getDeliveryInfo()
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    /**
     * Get delivery info
     */
    getDeliveryInfo: function () {
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      this.GET_DELIVERY_INFO_DETAIL(filter).then(
        function (res) {
          let data = res.data
          this.phone = data.phone
          this.email = data.email
          this.fullname = data.fullname
        }.bind(this)
      )
    },
    /**
     * Create delivery info
     */
    onCreateDeliveryInfoSuccess: function () {
      this.getDeliveryInfo()
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      history.back()
    },
    /**
     * Show modal update delivery info
     */
    onShowModalUpdateDeliveryInfo: function () {
      this.$refs.modalUpdateDeliveryInfo.onShowModal()
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    ...mapActions([
      'GET_DELIVERY_INFO_DETAIL'
    ])
  }
}
</script>

<style>

</style>
